import React, { useEffect, useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import './NotificationModal.css'
import { API_URL } from '../../server';
import { useNavigate } from 'react-router-dom';

const NotificationModal = ({ show, handleClose }) => {
  const [loader, setlodaer] = useState(false);
  const [data,setdata]=useState([]);
  const navigate = useNavigate();

  const gotosetting = ()=> {
      navigate("SettingsMainScreen/SettingNotification", {
        state : 2
      });
  }

  useEffect(()=> {
    getnotification();
  },[])

  const getnotification = async () => {
    try {
      const registerid = sessionStorage.getItem('registerid');
      const token = sessionStorage.getItem('token');
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", "Bearer " + token);

      const raw = JSON.stringify({
        "user_id": registerid
      });

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow"
      };

      const response = await fetch(API_URL + 'getNotification', requestOptions);
      const data = await response.json();
      console.log("data notification", data);
      setlodaer(false);
      setdata(data?.response);
    } catch (error) {
      console.log(error);
    }
  }

  function getRelativeTime(isoDate) {
    const now = new Date();
    const past = new Date(isoDate);
    const diffInMs = now - past; // Difference in milliseconds
    const diffInSeconds = Math.floor(diffInMs / 1000);
  
    if (diffInSeconds < 60) {
      return `${diffInSeconds}s ago`;
    }
  
    const diffInMinutes = Math.floor(diffInSeconds / 60);
    if (diffInMinutes < 60) {
      return `${diffInMinutes}m ago`;
    }
  
    const diffInHours = Math.floor(diffInMinutes / 60);
    if (diffInHours < 24) {
      return `${diffInHours}h ago`;
    }
  
    const diffInDays = Math.floor(diffInHours / 24);
    if (diffInDays < 7) {
      return `${diffInDays} day${diffInDays > 1 ? "s" : ""} ago`;
    }
  
    const diffInWeeks = Math.floor(diffInDays / 7);
    return `${diffInWeeks} week${diffInWeeks > 1 ? "s" : ""} ago`;
  }


  return (
    <div>
      <Modal show={show} onHide={handleClose} >
        <div className='notification-modal'>
          <div className='notificationmodal-cancelbutton'>
            <button
              type="button"
              className="btn btn-close ms-auto"
              onClick={handleClose}
            ></button>
          </div>
          <div className='notificationmodal-head'>
            <div className='notificationmodal-notification-text'>
              <span>Notifications</span>
            </div>
            <hr />
            <div className='notificationmodal-notificationsetting-conatiner' onClick={()=>gotosetting()}>
              <div className='notificationmodal-notificationsetting-icon' >
                <img src={require("../../Assets/setting.png")} width={18} height={18} />
                <span>Notification Settings</span>
              </div>
              <div>
                <img src={require("../../Assets/Vector (2).png")} width={9} height={9} />
              </div>
            </div>
            <hr />
            <div className='notificationmodal-body'>
              {loader ? (
                <span>Loading..</span>
              ) : (
                <>
                  {data?.length === 0 ? (
                    <span>No Data</span>
                  ) : (
                    <>
                      {data?.map((items,index) => (
                        <div key={index} className='notificationmodal-card'>
                          <div className='notificationmodal-card-head'>
                            <div className='notificationmodaal-cardhead-text'
                            onClick={()=> {
                              // items?.type == 'BookExpert' || items?.type == 'Course BookExpert' ? 
                              // navigate('/') : null
                            }}
                            >
                              <span>{items?.notificationData[0]?.body}</span>
                              <span>{getRelativeTime(items?.updatedAt)}</span>
                            </div>
                            <div className='notificationmodal-cardhead-dot'>
                              <span></span>
                            </div>
                          </div>
                          {/* <hr /> */}
                          {/* {items?.content ? (
                            <>
                              <div className='notificationmodal-card-content'>
                                <div className='notificationmodal-cardcontent-level'>
                                  <span>Level:</span>
                                  <span>{items?.content?.Level}</span>
                                </div>

                                <div className='notificationmodal-cardcontent-level'>
                                  <span>Message:</span>
                                  <span>{items?.notificationData[0]?.body}</span>
                                </div>
                              </div>
                              <hr />
                              <div className='notificationmodal-card-contentfooter-conatiner'>
                                <div className='notificationmodal-cardfooter'>
                                  <img src={require("../../Assets/accept.png")} width={20} height={20} />
                                  <span className='notification-Accept'>Accept</span>
                                </div>

                                <div className='notificationmodal-cardfooter'>
                                  <img src={require("../../Assets/reject.png")} width={20} height={20} />
                                  <span className='notification-reject'>Reject</span>
                                </div>
                              </div>
                            </>
                          ) : (
                            <>
                              <span className='notification-cardfooter'>View review and reply</span>
                            </>
                          )} */}
                        </div>
                      ))}
                    </>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default NotificationModal;