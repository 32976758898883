import { useLocation, useNavigate } from "react-router-dom";
import "./Headings.css";
import "../Layout/Layout.css"
import { Form, Modal, Button } from "react-bootstrap";
import { useEffect, useRef, useState } from "react";
import { API_URL, TEST_MODE_STRIPE_PUBLISHABLE_KEY } from "../../server";
import bellicon from "../../Assets/sblack/notiblack.svg"
import leftarrow from "../../Assets/left_arrow.svg"
import { useGoogleLogin } from "@react-oauth/google";
import GoogleApiservice from "../../server/GoogleApiservice";
import { getFirebaseToken, deleteFirebaseToken } from "../../firebase";
import { auth, googleProvider } from "../../firebase"
import person from "../../Assets/person.svg"
import { signInWithPopup, OAuthProvider } from "firebase/auth";
import courseicon from "../../Assets/courses.svg"
import eyeopen from "../../Assets/eyeopen.svg";
import { loadStripe } from "@stripe/stripe-js";
import { useStripe, useElements, CardElement, } from '@stripe/react-stripe-js';
import { Elements } from "@stripe/react-stripe-js";
import { ToastContainer, toast } from 'react-toastify';
import PaymentModal from "./PaymentModal";
const stripePromise = loadStripe(TEST_MODE_STRIPE_PUBLISHABLE_KEY);
const Headings = (props) => {
    const [isTokenFound, setTokenFound] = useState(null);
    const tokenInitialized = useRef(false);
    const inputRefs = useRef([]);
    const [otpChecking, setOtpChecking] = useState(false);
    const [passwordValue, setPasswordValue] = useState('');
    const [nameValue, setNameValue] = useState('');
    const navigate = useNavigate();

    // strip modal 
    const [stripmodal, setStripmodal] = useState(false);
    const [price, setPrice] = useState();
    const [cuurency, setCuurency] = useState('');
    const [clientSecret, setClientSecret] = useState('');
    const [striploading, setStriploading] = useState(false);
    const [IsProcessing, setIsProcessing] = useState(false);
    const [sessionCount, setSessionCount] = useState(null);
    const stripe = useStripe();
    const elements = useElements();

    //error related hooks started
    const [addressError, setAddressError] = useState('');
    const [showAddressError, setShowAddressError] = useState(false);
    const [isValidEmail, setIsValidEmail] = useState(true);
    const [otpError, setOtpError] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [nameError, setNameError] = useState('');
    const [loginPasswordError, setLoginPasswordError] = useState(false);
    const [invalidPasswordError, setInvalidPasswordError] = useState("");
    //error related hooks ended
    //modal hooks started
    const [account, setAccount] = useState(false);
    const [address, setAddress] = useState(false);
    const [otp, setOtp] = useState(false);
    const [password, setPassword] = useState(false);
    const [name, setName] = useState(false);
    const [enterLoginPassword, setenterLoginPassword] = useState(false);
    const [loginPassword, setLoginPassword] = useState('');
    const [button, setButton] = useState(false);
    const [amount, setAmount] = useState(false);
    const [end, setEnd] = useState(false);
    const [isSignedIn, setIsSignedIn] = useState(false);
    const isLoggedIn = sessionStorage.getItem("islogin")
    const [signout, setSignOut] = useState(false);

    const [emailAddress, setEmailAddress] = useState(null);
    const [passwordShown, setPasswordShown] = useState(false);

    const token = sessionStorage.getItem('token');
    const user_id = sessionStorage.getItem('user_id');
    const registerid = sessionStorage.getItem('registerid');
    const profileStatus = sessionStorage.getItem('profile');
    const username = sessionStorage.getItem('username');

    const location = useLocation();
    const loc = location?.state;
    const [status, setStatus] = useState(true);

    const retriveGoogle_credationals = async () => {
        try {
            const result = await signInWithPopup(auth, googleProvider);
            const user = result.user;
            console.log('User Info:', user);
            handelGoogle_login(user);
        } catch (error) {
            console.error('Error during sign in:', error);
        }
    }


    const handelGoogle_login = async (items) => {
        const profiledata = sessionStorage.getItem('profile');
        try {
            const myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");

            const raw = JSON.stringify({
                "email": items?.email,
                "token": isTokenFound,
                "device_id": "23456"
            });

            const requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: raw,
                redirect: "follow"
            };

            const result = await fetch(API_URL + `googleLogin`, requestOptions);
            const data = await result.json();
            console.log("result", data);
            if (!result.ok) {
                throw new Error("somenthing went wrong..")
            }
            sessionStorage.setItem('token', data?.data?.tokens?.accessToken);
            sessionStorage.setItem('registerid', data?.data?._id);
            sessionStorage.setItem('user_id', data?.data?._id);
            sessionStorage.setItem('username', data?.data?.name);
            sessionStorage.setItem('email', items?.email);
            sessionStorage.setItem('profile', data?.data?.profile);
            sessionStorage.setItem("stripId", data?.data?.accountId);
            if (!data?.data?.profile) {
                const userName = data?.data?.email.split('@')[0];
                console.log('userName', userName);
                createnewpassword(data?.data?._id, userName, userName)
            }
            accountClose();
            if(data?.data?.profile) {
                setEnd(true);
            }
            setIsSignedIn(true);
            sessionStorage.setItem("islogin", true);
        } catch (error) {
            console.log(error);
        }
    }


    const handleAppleLogin = async () => {
        const provider = new OAuthProvider('apple.com');

        try {
            // Trigger sign-in
            const result = await signInWithPopup(auth, provider);
            console.log('apple result', result);
            // Get the Apple ID token and access token from the result
            const credential = OAuthProvider.credentialFromResult(result);
            const user = result.user;
            handelGoogle_login(user);
            console.log('User info:', user);
            console.log('Credential:', credential);
        } catch (error) {
            console.error('Error during sign-in:', error);
        }
    }

    useEffect(() => {
        verifyuser();
        approveornot();
        showname();
        if (!tokenInitialized.current) {
            getFirebaseToken(setTokenFound);
            tokenInitialized.current = true; // Mark as initialized
        }
    }, []);

    useEffect(() => {
        getAmount();
    }, []);

    useEffect(() => {
        if (status === loc) {
            navigate('/')
            accountShow();
        }
        else {

        }
    }, [location])

    const handleSignIn = () => {

        //all values should be empty started
        setEmailAddress('');
        setPasswordValue('');
        setNameValue('');
        setLoginPassword('')
        setLoginPasswordError('');
        setInvalidPasswordError('');
        setForgetModuleEmail('');
        setShowAddressError('');
        setForgetPasswordValue('');
        setShowAddressError('');
        setForgetModuleEmailValue('');
        setotpErrorMessage('');
        setForgetPasswordValue('')
        setForgetPasswordError('')
        //all values should be empty ended

        accountShow();
    };

    // Function to handle sign-out
    const signoutClose = () => setSignOut(false);
    const handleSignOut = () => {
        setSignOut(true);
        sessionStorage.setItem("islogin", false);
        deleteFirebaseToken(setTokenFound)
    };

    const confirmSignOut = () => {
        setIsSignedIn(false);
        setSignOut(false);
        sessionStorage.clear();
        localStorage.clear();
        navigate("/");
    };
    //modal hook is ended

    useEffect(() => {
        if (profileStatus === 'true') {
            setIsSignedIn(true);
            sessionStorage.setItem("islogin", true);
        } else {
            setIsSignedIn(false);
            sessionStorage.setItem("islogin", false);
        }
    }, [profileStatus]);


    const togglePasswordVisibility = () => {
        setPasswordShown(!passwordShown);
    };
    const handleInputChange = (event) => {
        const email = event.target.value;
        setEmailAddress(email);
        if (validateEmail(email)) {
            setIsValidEmail(true);
            setShowAddressError(false);
            setAddressError('');
        } else {
            setIsValidEmail(false);
            if (email) {
                setAddressError('Please enter a valid email address.');
                setShowAddressError(true);
            }
        }
    };
    const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };
    //  related to otp module started
    const handleSubmit = (event) => {
        event.preventDefault();
        const otp = inputRefs.current.map(input => input.value).join('');
        otpVerification(otp);
    };

    const handleChange = (e, index) => {
        const value = e.target.value;

        // Allow only digits and move forward only if a digit is entered
        if (/^\d$/.test(value)) {
            inputRefs.current[index].value = value;  // Set value explicitly to handle direct input
            if (index < 5) {
                inputRefs.current[index + 1].focus();  // Move to next field
            }
        } else if (value === '') {
            // Allow empty input to clear the field without moving the cursor automatically
            inputRefs.current[index].value = ''; // Clear field
        }
    };

    const handleKeyDown = (e, index) => {
        if (e.key === "Backspace" && !inputRefs.current[index].value && index > 0) {
            // If backspace on empty field, move to previous field
            inputRefs.current[index - 1].focus();
        } else if (e.key === "ArrowLeft" && index > 0) {
            inputRefs.current[index - 1].focus(); // Move left with arrow key
        } else if (e.key === "ArrowRight" && index < 5) {
            inputRefs.current[index + 1].focus(); // Move right with arrow key
        }
    };

    const clearOtpInputs = () => {
        inputRefs.current.forEach(input => input.value = '');
        inputRefs.current[0].focus();
    };
    // related to top module ended

    //modal related modal open and and close started
    const accountClose = () => {
        setAccount(false)
    };
    const accountShow = () => {
        setPassword(false)
        setAddress(false);
        setOtp(false);
        setAccount(true);
    }
    const addressClose = () => setAddress(false);
    const addressShow = () => {
        setPassword(false)
        accountClose(false);
        setAccount(false);
        setOtp(false);
        setAddress(true);
    }
    const otpClose = () => setOtp(false);
    const otpShow = () => {
        signIn();
        setShowAddressError(false);
        setOtp(true);
        setPassword(false);
        accountClose(false);
        setAddress(false)
        setAddressError('');
    };
    const passwordClose = () => setPassword(false);
    const passwordShow = () => {
        setName(false);
        setOtp(false);
        setPassword(true);
    };
    const nameClose = () => setName(false);
    const nameShow = () => {
        if (passwordValue === '') {
            setPasswordError('Password cannot be empty.');
        } else if (passwordValue.length < 8) {
            setPasswordError('Password must be at least 8 characters long.');
        } else {
            setPasswordError('');
            setenterLoginPassword(false);
            setPassword(false);
            setName(true);
        }
    };

    const enterLoginPasswordClose = () => setenterLoginPassword(false);
    const enterLoginPasswordShow = () => {
        sessionStorage.setItem('profile', true);
        if (nameValue === '') {
            setNameError('Name cannot be empty.');
        } else {
            setNameError('');
            setName(false);
            createnewpassword();
            // setAmount(true);
        }
    };
    const amountClose = () => {
        // setAmount(false);
        closestripModal();
        setIsSignedIn(true)
        sessionStorage.setItem("islogin", true);
        window.location.reload();
    }
    const amountShow = () => {
        setenterLoginPassword(false);
        setEnd(true);
    }
    const endClose = () => {
        setIsSignedIn(true)
        sessionStorage.setItem("islogin", true);
        setEnd(false)
        window.location.reload()
    };

    const verifyuser = () => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", "Bearer " + token);

        const raw = JSON.stringify({
            "expert_id": registerid
        });

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow"
        };

        fetch(API_URL + "getBexomeExpertById", requestOptions)
            .then((response) => response.json())
            .then((result) => {
                if (result.Status == true) {
                    sessionStorage.setItem('checkingverification', result.data[0].adminStatus);
                }
            })
            .catch((error) => console.error(error));
    }
    //modal related open and close ended

    const expertscreen = (keyname) => {
        if (keyname == "homeScreen") {
            navigate('/')
        }
        else if (keyname == "Courses") {
            navigate('/CardCourses');
        }
        else if (keyname == "settingMainScreen") {
            if (registerid === null) {
                navigate('/', {
                    state: true
                })
            }
            else {
                navigate('/SettingsMainScreen/SettingNotification')
            }
        }
    }

    // sign api started
    const signIn = () => {
        // Perform validation checks before proceeding
        if (!emailAddress) {
            setAddressError('Email address cannot be empty.');
            setIsValidEmail(false);
            setShowAddressError(true);
            return;
        } else if (!validateEmail(emailAddress)) {
            setAddressError('Please enter a valid email address.');
            setIsValidEmail(false);
            setShowAddressError(true);
            return;
        }

        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        const raw = JSON.stringify({
            "email": emailAddress,
            "token": isTokenFound,
            "device_id": "23456"
        });

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow"
        };
        console.log("loginn raw", raw);
        fetch(API_URL + "userRegistration", requestOptions)
            .then((response) => response.json())
            .then((result) => {
                console.log('result', result);
                if (result.Status == true) {
                    console.log("signIn ", result.data.emailotp);
                    sessionStorage.setItem('token', result.data.tokens.accessToken);
                    sessionStorage.setItem('registerid', result.data._id);
                    sessionStorage.setItem("userEmail", result?.data);
                    sessionStorage.setItem('email', result?.data?.email);
                    setAddress(false)
                    setOtp(true)
                    // otpShow();
                }
                else if (result.Status == false) {
                    console.log("signIn ", result);
                    setAddress(false)
                    setenterLoginPassword(true)
                    sessionStorage.setItem('token', result.data.tokens.accessToken);
                    sessionStorage.setItem('registerid', result.data._id)
                }
            })
            .catch((error) => console.error(error));
    };
    const signInAgain = () => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        const raw = JSON.stringify({
            "email": emailAddress,
            "token": isTokenFound,
            "device_id": "23456"
        });

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow"
        };

        fetch(API_URL + "userRegistration", requestOptions)
            .then((response) => response.json())
            .then((result) => {
                if (result.Status == true) {
                    console.log("signIn ", result.data.emailotp);
                    sessionStorage.setItem('token', result.data.tokens.accessToken);
                    sessionStorage.setItem('registerid', result.data._id);
                    sessionStorage.setItem('email', result?.data?.email);
                }
                else if (result.Status == false) {
                    console.log("signIn ", result);
                    sessionStorage.setItem('token', result.data.tokens.accessToken);
                    sessionStorage.setItem('registerid', result.data._id)
                }
            })
            .catch((error) => console.error(error));
    };
    //sign api ended

    //   otp api started
    const otpVerification = (funotp) => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", "Bearer " + token);

        const raw = JSON.stringify({
            "email": emailAddress,
            "emailotp": funotp
        });

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow"
        };

        fetch(API_URL + "userOtpVerify", requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.Status === true) {
                    setOtpChecking(true);
                    setOtpError('');
                    sessionStorage.setItem('user_id', result.data._id);
                    console.log("userOtpVerify", result);
                    passwordShow();
                } else {
                    setOtpChecking(false);
                    setOtpError('Invalid OTP');
                    clearOtpInputs();
                }
            })
            .catch(error => {
                console.error(error);
                setOtpChecking(false);
                setOtpError('An error occurred. Please try again.');
            });
    };


    // otp api ended

    //createNewPassword api started
    const createnewpassword = (_id, userName, Password) => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        const raw = JSON.stringify({
            "user_id": _id ? _id : user_id,
            "password": Password ? Password : passwordValue,
            "name": userName ? userName : nameValue
        });

        console.log("raw..........", raw)

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow"
        };

        fetch(API_URL + "createNewPassword", requestOptions)
            .then((response) => response.json())
            .then((result) => {
                console.log("createNewPassword", result);
                sessionStorage.setItem('profile', result.data.profile)
                sessionStorage.setItem('registerid', result.data._id);
                    openstripModal();
            })
            .catch((error) => console.error(error));
    }
    //createNewPassword api ended

    //login api started
    const userLogin = () => {
        if (loginPassword.trim() === "") {
            setLoginPasswordError(true);
            setInvalidPasswordError("");
            return;
        } else {
            setLoginPasswordError(false);
        }
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", "Bearer " + token);

        const raw = JSON.stringify({
            email: emailAddress,
            password: loginPassword,
            token: isTokenFound,
            device_id: "di"
        });
        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow"
        };
        console.log('raw of login', raw);
        fetch(API_URL + "userLogin", requestOptions)
            .then((response) => response.json())
            .then((result) => {
                console.log("userLogin ::", result);
                if (result.Status == true) {
                    sessionStorage.setItem('profile', result.data.profile);
                    sessionStorage.setItem('user_id', result.data._id);
                    sessionStorage.setItem('registerid', result.data._id)
                    sessionStorage.setItem('username', result.data.name)
                    sessionStorage.setItem('token', result.data.tokens.accessToken);
                    sessionStorage.setItem("userEmail", result?.data?.email);
                    sessionStorage.setItem("stripId", result?.data?.accountId);
                    sessionStorage.setItem("email", result?.data?.email);
                    setenterLoginPassword(false);
                    setEnd(true);
                    // openstripModal();
                    setInvalidPasswordError("");
                } else {
                    console.log("error :: invalid password");
                    setInvalidPasswordError("Invalid password. Please try again.");
                }
            })
            .catch((error) => {
                console.error(error);
                setInvalidPasswordError("An error occurred. Please try again.");
            });
    };
    //login api ended

    //forgetPassword api started
    const [forgetModuleEmail, setForgetModuleEmail] = useState(false);
    const [forgetOtpModule, setForgetOtpModule] = useState(false);
    const [forgetModuleEmailValue, setForgetModuleEmailValue] = useState('');
    const [forgetOtpValue, setForgetOtpValue] = useState('');
    const [otpErrorMessage, setotpErrorMessage] = useState('');
    const [forgetPasswordModule, setForgetPasswordModule] = useState(false);
    const [forgetPasswordValue, setForgetPasswordValue] = useState('');
    const [forgetPasswordError, setForgetPasswordError] = useState("");

    const forgetuserid = sessionStorage.getItem("user_id");
    //   forgot otp related functionality started
    const forgetPasswordClose = () => {
        setForgetPasswordModule(false);
    }
    const forgetOtpModuleClose = () => setForgetOtpModule(false)
    const forgetOtpModuleShow = () => {
        forgetPasswordClose();
        setForgetOtpModule(true);
    }
    const handleOtpSubmit = (event) => {
        event.preventDefault();
        const otp = inputRefs.current.map(input => input.value).join('');
        if (otp.length === 6) {
            otpreset(otp);
        } else {
            setotpErrorMessage("Invalid Otp Please Enter Again!!");
        }
    };
    const handleOtpChange = (e, index) => {
        const value = e.target.value;
        if (/^[0-9]*$/.test(value)) { // Allow only numeric input
            inputRefs.current[index].value = value; // Set the value explicitly
            if (value.length === 1 && index < 5) {
                inputRefs.current[index + 1].focus();
            }
        }
        const otpValue = inputRefs.current.map((ref) => ref.value).join('');
        if (otpValue.length === 6) {
            setotpErrorMessage('');
            otpreset(otpValue);
        }
    };

    const handleOtpKeyDown = (e, index) => {
        if (e.key == 'Backspace' && index > 0 && !inputRefs.current[index].value) {
            inputRefs.current[index - 1].focus();
        }
    };

    //   forgot otp related functionality ended 

    const forgetModuleEmailClose = () => setForgetModuleEmail(false);
    const forgetModuleEmailShow = () => {
        setForgetOtpModule(false)
        enterLoginPasswordClose();
        setForgetModuleEmail(true);

    }
    const ForgetPassword = () => {
        if (!forgetModuleEmailValue) {
            setAddressError('Email address cannot be empty.');
            setIsValidEmail(false);
            setShowAddressError(true);
            return;
        } else if (!validateEmail(forgetModuleEmailValue)) {
            setAddressError('Please enter a valid email address.');
            setIsValidEmail(false);
            setShowAddressError(true);
            return;
        }
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", "Bearer " + token);

        const raw = JSON.stringify({
            "email": forgetModuleEmailValue,
        });
        console.log("raw of forgetpassword", raw)
        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow"
        };

        fetch(API_URL + "forgetPassword", requestOptions)
            .then((response) => response.json())
            .then((result) => {
                if (result.Status == true) {
                    console.log("forgetPassword", result)
                    sessionStorage.setItem('user_id', result.data._id);
                    sessionStorage.setItem('registerid', result.data._id)
                    setAddressError('');
                    setForgetOtpValue(result.data.emailotp);
                    setForgetModuleEmail(false);
                    setForgetOtpModule(true);
                }
            })
            .catch((error) => console.error(error));
    }
    const ForgetPasswordAgain = () => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", "Bearer " + token);

        const raw = JSON.stringify({
            "email": forgetModuleEmailValue,
        });

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow"
        };

        fetch(API_URL + "forgetPassword", requestOptions)
            .then((response) => response.json())
            .then((result) => {
                if (result.Status == true) {
                    console.log("forgetPassword", result.data.emailotp)
                    sessionStorage.setItem('user_id', result.data._id);
                    sessionStorage.setItem('registerid', result.data._id)
                }
            })
            .catch((error) => console.error(error));
    }

    const otpreset = (otpresetvalue) => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", "Bearer " + token);

        const raw = JSON.stringify({
            email: forgetModuleEmailValue,
            emailotp: otpresetvalue,
        });

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow"
        };

        fetch(API_URL + "verifyOtpForPasswordReset", requestOptions)
            .then((response) => response.json())
            .then((result) => {
                if (result.Status === true) {
                    console.log("verifyOtpForPasswordReset", result);
                    setForgetOtpModule(false)
                    setForgetPasswordModule(true)
                } else {
                    setotpErrorMessage("Invalid Otp Please Enter Again!!");
                }
            })
            .catch((error) => console.error(error));
    };

    const forgetenternewpassword = () => {
        if (forgetPasswordValue.length >= 8) {
            // Clear any existing error messages
            setForgetPasswordError("");

            const myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            myHeaders.append("Authorization", "Bearer " + token);

            const raw = JSON.stringify({
                "user_id": user_id,
                "password": forgetPasswordValue,
            });

            const requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: raw,
                redirect: "follow"
            };

            fetch(API_URL + "createNewPassword", requestOptions)
                .then((response) => response.json())
                .then((result) => {
                    console.log("createNewPassword", result);
                    setForgetPasswordModule(false)
                    setAccount(true)
                    // Handle success, maybe close the modal or show a success message
                })
                .catch((error) => console.error(error));
        } else {
            // Set error message
            setForgetPasswordError("Password must be at least 8 characters long.");
        }
    };
    //forgetPassword

    // useEffect(()=>{
    //     approveornot();
    //     showname();
    // },[])
    const approveornot = () => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", "Bearer " + token);

        const raw = JSON.stringify({
            "expert_id": registerid
        });

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow"
        };

        fetch(API_URL + "getBexomeExpertById", requestOptions)
            .then((response) => response.json())
            .then((result) => {
                if (result.Status == true) {
                    console.log("getBexomeExpertById", result.data)
                    sessionStorage.setItem('approvedornot', result.data[0].adminStatus)
                }
            })
            .catch((error) => console.error(error));
    }
    const showname = () => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", "Bearer " + token);

        const raw = JSON.stringify({
            "_id": registerid
        });

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow"
        };

        fetch(API_URL + "getUsers", requestOptions)
            .then((response) => response.json())
            .then((result) => {
                if (result.Status == true) {
                    console.log("getUsers", result)
                    sessionStorage.setItem('addname', result.data.profile.name);
                }
            })
            .catch((error) => console.error(error));
    }


    // strip modal 
    const openstripModal = () => {
        setStripmodal(true);
    }

    const closestripModal = () => {
        setStripmodal(false);

    }




    const getAmount = async () => {
        try {
            const token = sessionStorage.getItem('token');
            const myHeaders = new Headers();
            // myHeaders.append("Authorization", "Bearer " + token);

            const requestOptions = {
                method: "POST",
                headers: myHeaders,
                redirect: "follow"
            };
            const response = await fetch(API_URL + 'getAdminSessionPrice', requestOptions);
            const result = await response.json();
            console.log("getAdminSessionPrice", result);
            if (!response.ok) {
                throw new Error("Data not found");
            }
            setCuurency(result?.data[0]?.currency);
            setPrice(Number(result?.data[0]?.sessionPrice));
        } catch (error) {
            console.log("error", error);
        }
    }



    return (
        <div>
            <ToastContainer />
            <div className="headerone">
                <div className="containerheader" >
                    <div>
                        <p className="headerprofile">{props.screenHeadingName}</p>
                    </div>
                    <div className="btn-leftheader">
                        <div className="btn-leftheadersub">
                            <img src={require("../../Assets/home.png")} className="icon_top" />
                            <span onClick={() => expertscreen("homeScreen")} className="nav_names">Home</span>
                        </div>

                        <div className="btn-leftheadersub">
                            <img src={courseicon} className="icon_top" />
                            <span onClick={() => expertscreen("Courses")} className="nav_names">Courses</span></div>

                        <div className="btn-leftheadersub">
                            <img src={person} className="icon_top" />
                            <span onClick={() => expertscreen("settingMainScreen")} className="nav_names">Account</span></div>


                        {profileStatus ? (
                            <div onClick={handleSignOut} className="btn-leftheadersub123 pe-3">
                                <img src={require("../../Assets/sign-out.png")} style={{ height: 24, width: 25 }} />
                                &nbsp;Sign out
                            </div>
                        ) : (
                            <div onClick={handleSignIn} className=" btn-leftheadersub123">
                                <img src={require("../../Assets/sign-in.png")} style={{ height: 24, width: 25 }} />
                                &nbsp;Sign in
                            </div>
                        )}
                    </div>
                </div>
            </div>

            {/* account started popup started */}
            <div>
                <Modal centered show={account} className="modal">
                    <Modal.Body className="modalbackgroundcolor modalhightandwidth">
                        <div className="d-flex">
                            <button
                                type="button"
                                className="btn btn-close ms-auto"
                                onClick={() => setAccount(false)}
                            >
                            </button>
                        </div>
                        <p className="poptitle">Use an account to continue</p>
                        <span className="wave_img"><p className="popparagraph">
                            Get $10 in your wallet as a gift from us to kickstart your learning when you create a new account.
                        </p></span>
                        <div className="d-flex justify-content-center mt-2 mb-2">
                            <img className="popimage" src={require("../../Assets/waves.png")} />
                        </div>
                        <div style={{ textAlign: "center" }}>
                            <p>
                                <button
                                    onClick={handleAppleLogin}
                                    className="bi bi-apple popupbuttons bg-black text-white">&nbsp;
                                    Continue with Apple
                                </button>
                            </p>
                            <p>
                                <button className="popupbuttons bg-white" onClick={() => retriveGoogle_credationals()}>
                                    <img className="googleimage" src={require("../../Assets/google.png")} alt="Google" />&nbsp;
                                    Continue with Google
                                </button>
                            </p>
                            <p>
                                <button onClick={addressShow} className="bi bi-envelope-fill popupbuttons" style={{ backgroundColor: "#F5F3EE" }}>&nbsp;
                                    Use email address
                                </button>
                            </p>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
            {/* account started popup ended */}

            {/* address popup started */}
            <Modal centered show={address} className="modal">
                <Modal.Body style={{ backgroundColor: "#F5F3EE" }} className="modalhightandwidth">
                    <div className="d-flex justify-content-between align-items-center mt-1">
                        <div>
                            <button onClick={() => accountShow()} className="addressback d-flex align-items-center"><img src={leftarrow} style={{ height: '15px', width: '15px' }} />&nbsp;&nbsp;Back</button>
                        </div>
                        <div>
                            <button
                                type="button"
                                className="btn btn-close ms-auto mb-2"
                                onClick={addressClose}
                            >
                            </button>
                        </div>
                    </div>
                    <div style={{ marginLeft: "30px" }}>
                        <p className="addressheader">What’s your email?</p>
                        <p className="addresspara">We verify all accounts to create a genuine learning community with real Learners and tutors.</p>
                        <div>
                            <p className="address">Email address</p>
                            <p>
                                <input
                                    type="email"
                                    value={emailAddress}
                                    onChange={handleInputChange}
                                    placeholder="Enter here"
                                    className="addressinput"
                                />
                                {showAddressError && (
                                    <p style={{ color: '#7A0000' }}>{addressError}</p>
                                )}
                            </p>
                            <p>
                                <button onClick={signIn} className="addressbutton mb-5">
                                    Continue
                                </button>
                            </p>
                        </div>
                    </div>

                    <p className="addresslock"><img style={{ width: "17.6px", height: "20px", marginBottom: "5px" }} src={require("../../Assets/lock.png")} />We won’t share this information with anyone and it will remain confidential</p>
                </Modal.Body>
            </Modal>
            {/* address popup ended */}

            {/* otp screen started  */}
            <Modal centered show={otp} className="modal">
                <Modal.Body className="modalbackgroundcolor modalhightandwidth">

                    <div className="d-flex mt-2">
                        <button onClick={() => addressShow()} className="addressback d-flex align-items-center">
                            <img src={leftarrow} style={{ height: '15px', width: '15px' }} />&nbsp;Back
                        </button>
                        <button
                            type="button"
                            className="btn btn-close ms-auto"
                            onClick={otpClose}
                        >
                        </button>
                    </div>

                    <div style={{ marginLeft: "30px" }}>
                        <p className="otpheader">Verify your email</p>
                        <p className="otpenteremail">Enter the code we’ve sent to {emailAddress}</p>
                        <button onClick={() => accountShow()} className="otpchangemail"><u className="change_email">Change email</u></button>
                        <p className="otpentercode">Enter code</p>
                        <form onSubmit={handleSubmit}>
                            <div>
                                {Array(6).fill(null).map((_, index) => (
                                    <input
                                        key={index}
                                        className="otpinput"
                                        type="text"
                                        maxLength="1"
                                        onChange={(e) => handleChange(e, index)}
                                        onKeyDown={(e) => handleKeyDown(e, index)}
                                        ref={el => inputRefs.current[index] = el}
                                    />
                                ))}
                                <p style={{ color: "#7A0000" }}>{otpError}</p>
                            </div>
                            <div>
                                <button type="submit" className="otpbutton">Continue</button>
                            </div>
                        </form>
                    </div>
                    <div className="d-flex justify-content-between" style={{ marginTop: '130px' }}>
                        <span><u className="otpfooter" onClick={() => signInAgain()}>Didn’t get a code?</u></span>
                        <span className="otpfooter">Step 2/4</span>
                    </div>
                </Modal.Body>
            </Modal>
            {/* opt screen ended */}

            {/* password screen started */}
            <Modal centered show={password} className="modal">
                <Modal.Body className="modalbackgroundcolor modalhightandwidth">
                    <div className="d-flex mt-2">
                        <button onClick={otpShow} className="addressback d-flex align-items-center"><img src={leftarrow} style={{ height: '15px', width: '15px' }} />&nbsp;&nbsp;Back</button>
                        <button
                            type="button"
                            className="btn btn-close ms-auto"
                            onClick={passwordClose}
                        >
                        </button>
                    </div>
                    <div style={{ marginLeft: "30px" }}>
                        <p className="passheader1">Create a password</p>
                        <p className="pass">Password</p>
                        <p className="passpara">You need to use a minimum of eight characters and at least one uppercase letter for your password.</p>
                        <div className="password-wrapper" style={{ position: 'relative' }}>
                            <input
                                className="passinput"
                                type={passwordShown ? "text" : "password"}
                                style={{ paddingRight: '2.5rem' }}
                                placeholder="Enter here"
                                value={passwordValue}
                                onChange={(e) => setPasswordValue(e.target.value)}
                            />
                            <img
                                src={(passwordShown == false) ? require("../../Assets/eye_icon.png") : eyeopen}
                                className="eyeIcon"
                                onClick={togglePasswordVisibility}
                                alt="Toggle visibility"
                            />
                        </div>
                        <p style={{ color: "#7A0000" }}>{passwordError}</p>
                        <button onClick={nameShow} className="passbutton mb-5">Continue</button>
                        <p className="passpageno">Step 3/4</p>
                    </div>
                </Modal.Body>
            </Modal>

            {/* password screen ended */}

            {/* name screen started */}
            <Modal centered show={name} className="modal">
                <Modal.Body className="modalbackgroundcolor modalhightandwidth">
                    <div className="d-flex mt-2">
                        <button onClick={passwordShow} className="addressback d-flex align-items-center"><img src={leftarrow} style={{ height: '15px', width: '15px' }} />&nbsp;&nbsp;Back</button>
                        <button
                            type="button"
                            className="btn btn-close ms-auto"
                            onClick={nameClose}
                        >
                        </button>
                    </div>
                    <div style={{ marginLeft: "30px" }}>
                        <p className="passheader1">What’s your name?</p>
                        <p className="pass">Name</p>
                        <p className="passpara">You can always edit your name later in Settings.</p>
                        <p>
                            <input
                                className="passinput"
                                type="text"
                                placeholder="Enter here"
                                value={nameValue}
                                onChange={(e) => { setNameValue(e.target.value) }}
                            />
                        </p>
                        <p style={{ color: "#7A0000" }}>{nameError}</p>
                        <p>
                            <button onClick={enterLoginPasswordShow} className="passbutton mb-5">Continue</button>
                        </p>
                        <p className="passpageno">Step 4/4</p>
                    </div>
                </Modal.Body>
            </Modal>

            {/* name screen ended */}

            {/* balance screen started */}
            <Modal centered show={amount} className="modal">
                <Modal.Body className="modalbackgroundcolor modalhightandwidth">
                    <div className="d-flex justify-content-end">
                        <button
                            className="btn btn-close"
                            onClick={() => setAmount(false)}
                        >
                        </button>
                    </div>

                    <div>
                        <p className="amounthead">Welcome,{nameValue}.</p>
                        <p className="amountpara">
                            Your account is ready, which you can also use to share your
                            own knowledge as an tutor. Here’s <span style={{ fontFamily: 'AvenirNextLTPro-Bold' }}>$10</span> in your wallet as a
                            gift from us to kickstart your learning.
                        </p>
                        <div className="amountdisplayflex">
                            <div>
                                <img className="amountimage" src={require("../../Assets/wallet.png")} />
                            </div>
                            <div style={{ marginTop: "14px" }}>
                                <p className="amountpara al mt-2">Available balance</p>
                                <p className="amount ms-5">$10.00</p>
                            </div>
                        </div>
                        <hr className="amountline"></hr>
                        <p className="amounthead">Earn an additional $10</p>
                        <p className="amountpara">If you received an Invite Code from a friend, please enter
                            it below to receive an additional <span style={{ fontFamily: 'AvenirNextLTPro-Bold' }}>$10 </span>.
                        </p>
                        <p className="amount_input">
                            <input className="amountinput" type="text" placeholder="Enter Invite Code (optional)" />
                        </p>
                        <p className="amountbtn"><button onClick={() => amountClose()} className="amountbutton passbutton">Continue</button></p>
                    </div>
                </Modal.Body>
            </Modal>
            {/* balance screen ended */}

            {/* 2 login password screen started */}
            <Modal centered show={enterLoginPassword}>
                <Modal.Body className="modalbackgroundcolor modalhightandwidth">

                    <div className="d-flex">
                        <button onClick={() => addressShow()} className="addressback d-flex align-items-center"><img src={leftarrow} style={{ height: '15px', width: '15px' }} />&nbsp;&nbsp;Back</button>
                        <button
                            type="button"
                            className="btn btn-close ms-auto"
                            onClick={enterLoginPasswordClose}
                        >
                        </button>
                    </div>
                    <div style={{ marginLeft: "30px" }}>
                        <p className="passheader1">Enter your password</p>
                        <p className="pass">Password</p>
                        <p>
                            <input
                                type={passwordShown ? "text" : "password"} // Change here
                                placeholder="Enter here"
                                className="passinput"
                                value={loginPassword}
                                onChange={(e) => setLoginPassword(e.target.value)}
                            />
                            <img
                                src={(passwordShown == false) ? require("../../Assets/eye_icon.png") : eyeopen}
                                className="eyeIcon"
                                onClick={togglePasswordVisibility}
                                alt="Toggle visibility"
                            />
                        </p>

                        <p>
                            {loginPasswordError && <p style={{ color: "#7A0000" }}>Password should not be empty</p>}
                            {invalidPasswordError && <p style={{ color: "#7A0000" }}>{invalidPasswordError}</p>}
                            <button onClick={userLogin} className="passbutton">Continue</button>
                        </p>
                        <u onClick={() => forgetModuleEmailShow()} className="passpageno float-start">Forgot Password?</u>
                        <span className="passpageno float-end">Step 2/2</span>
                    </div>
                </Modal.Body>
            </Modal>
            {/* enterLoginPassword screen ended */}

            {/* end module started */}
            <Modal centered show={end} className="modal">
                <Modal.Body className="modalbackgroundcolor modalhightandwidth">
                    <div className="d-flex justify-content-end">
                        <button
                            type="button"
                            className="btn btn-close ms-auto"
                            onClick={() => setEnd(false)}
                        >
                        </button></div>
                    <div>
                        <p className="passheader2 endhead" style={{}}>Welcome back, {username}.</p>
                        <span className="wave_img"> <img className="endimage" src={require("../../Assets/strightwaves.png")} /></span>
                        <p onClick={() => endClose()} className="endbutton"><button className="passbutton" style={{ width: "142px", height: "32px" }}>Continue</button></p>
                    </div>
                </Modal.Body>
            </Modal>
            {/* end moudle ended */}

            {/* signout module started */}
            <Modal centered show={signout} className="modal">
                <Modal.Body className="modalbackgroundcolor modalhightandwidth">
                    <p style={{ textAlign: "center" }} className="pass mb-5">Are you sure you want to sign out?</p>
                    <div className="d-flex justify-content-center mb-3" style={{}}>
                        <Button style={{ fontFamily: "AvenirNextLTPro-Regular" }} variant="dark" className="passbutton" onClick={signoutClose}>
                            Cancel
                        </Button>
                        <Button style={{ fontFamily: "AvenirNextLTPro-Regular" }} variant="dark" onClick={confirmSignOut} className="ms-3 passbutton">
                            Yes
                        </Button>
                    </div>
                </Modal.Body>
            </Modal>
            {/* signout module ended */}

            {/* forgatpassword modules started */}
            <Modal centered show={forgetModuleEmail} className="modal">
                <Modal.Body style={{ backgroundColor: "#F5F3EE" }} className="modalhightandwidth">
                    <div className="d-flex">
                        <button onClick={() => {
                            setenterLoginPassword(true);;
                            setForgetModuleEmail(false)
                        }
                        } className="addressback d-flex align-items-center"><img src={leftarrow} style={{ height: '15px', width: '15px' }} />&nbsp;&nbsp;Back</button>
                        <button
                            type="button"
                            className="btn btn-close ms-auto"
                            onClick={forgetModuleEmailClose}
                        >
                        </button>
                    </div>
                    <div style={{ marginLeft: "30px" }}>
                        <div>
                            <p className="address" style={{ marginTop: "100px" }}>Email address</p>
                            <p>
                                <input
                                    type="email"
                                    value={forgetModuleEmailValue}
                                    onChange={(e) => { setForgetModuleEmailValue(e.target.value) }}
                                    placeholder="Enter your email"
                                    className="addressinput"
                                />
                                {showAddressError && (
                                    <p style={{ color: '#7A0000' }}>{addressError}</p>
                                )}
                            </p>
                            <p>
                                <button onClick={() => ForgetPassword()} className="addressbutton">
                                    Continue
                                </button>
                            </p>
                        </div>
                    </div>
                    <p className="addresslock email_below_text"><img style={{ width: "17.6px", height: "20px", marginBottom: "5px" }} src={require("../../Assets/lock.png")} /><span>We won’t share this information with anyone and it will remain confidential</span></p>
                </Modal.Body>
            </Modal>

            {/* forget otp screen started  */}
            <Modal centered show={forgetOtpModule} className="modal">
                <Modal.Body className="modalbackgroundcolor modalhightandwidth">
                    <div className="d-flex">
                        <button onClick={forgetModuleEmailShow} className="addressback d-flex align-items-center"><img src={leftarrow} style={{ height: '15px', width: '15px' }} />&nbsp;&nbsp;Back</button>
                        <button
                            type="button"
                            className="btn btn-close ms-auto"
                            onClick={forgetOtpModuleClose}
                        >
                        </button>
                    </div>
                    <div style={{ marginLeft: "30px" }}>
                        <p className="otpheader">Verify your email</p>
                        <p className="otpenteremail">Enter the code we’ve sent to {forgetModuleEmailValue}</p>
                        <p className="otpentercode">Enter code</p>
                        <form onSubmit={handleOtpSubmit}>
                            <div>
                                {Array(6).fill(null).map((_, index) => (
                                    <input
                                        key={index}
                                        className="otpinput"
                                        type="text"
                                        maxLength="1"
                                        onChange={(e) => handleOtpChange(e, index)}
                                        onKeyDown={(e) => handleOtpKeyDown(e, index)}
                                        ref={(el) => (inputRefs.current[index] = el)}
                                    />
                                ))}
                                <p style={{ color: '#7A0000', marginTop: '20px' }}>{otpErrorMessage}</p>
                            </div>
                        </form>
                        <div style={{ marginTop: '130px' }}></div>
                    </div>
                </Modal.Body>
            </Modal>
            {/* forget opt screen ended */}
            <Modal centered show={forgetPasswordModule} className="modal">
                <Modal.Body className="modalbackgroundcolor modalhightandwidth">
                    <div className="d-flex">
                        <button onClick={forgetOtpModuleShow} className="addressback d-flex align-items-center"><img src={leftarrow} style={{ height: '15px', width: '15px' }} />&nbsp;&nbsp;Back</button>
                        <button
                            type="button"
                            className="btn btn-close ms-auto"
                            onClick={forgetPasswordClose}
                        >
                        </button>
                    </div>
                    <div style={{ marginLeft: "30px" }}>
                        <p className="passheader1">Create a password</p>
                        <p className="pass">Password</p>
                        <p className="passpara">You need to use a minimum of eight characters and at least one uppercase letter for your password.</p>
                        <div className="password-wrapper" style={{ position: 'relative' }}>
                            <input
                                className="passinput"
                                type={passwordShown ? "text" : "password"}
                                style={{ paddingRight: '2rem' }}
                                placeholder="Enter here"
                                value={forgetPasswordValue}
                                onChange={(e) => { setForgetPasswordValue(e.target.value) }}
                            />
                            <img
                                src={(passwordShown == false) ? require("../../Assets/eye_icon.png") : eyeopen}
                                className="eyeIcon"
                                onClick={togglePasswordVisibility}
                                alt="Toggle visibility"
                            />
                        </div>
                        <p style={{ color: "#7A0000" }}>{forgetPasswordError}</p>
                        <button onClick={forgetenternewpassword} className="passbutton">Continue</button>
                        <p className="passpageno">Step 3/4</p>
                    </div>
                </Modal.Body>
            </Modal>
            {/* forgetPassword modules ended */}
            <Elements stripe={stripePromise} >
                <PaymentModal
                    stripmodal={stripmodal}
                    clientSecret={clientSecret}
                    closestripModal={closestripModal}
                    price={price}
                    cuurency={cuurency}
                />
            </Elements>
        </div>
    );
}
export default Headings;