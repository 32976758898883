import React from 'react';

export const CountContext = React.createContext();

export const API_URL ="https://learnatrecess.com:7001/"
export const IMG_PATH = "https://recessbucket.s3.ap-south-1.amazonaws.com/images/"
export const Recording_path = 'https://recessbucket.s3.ap-south-1.amazonaws.com/'
export const OauthClientId = '768259904297-cou8rkoi91kkg1cft7m086f2car780fs.apps.googleusercontent.com';
export const Agora_appId = 'c6d46cc870b64d13a457e19da4e11ac8';
export const Agora_chat_appKey = '611164130#1392597';
export const STRIPE_PUBLISHABLE_KEY = "pk_live_51PwxqRHfUH12ERnh8ANpb3w3xS6pBvlMBVNy0U3QVwFjNsrFyREJ11GPFpEnDSraaxnyZgrBGrDTqLS0J90lOxpw00M9Unaul7";
export const STRIPE_SECRET_KEY = "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";
export const TEST_MODE_STRIPE_PUBLISHABLE_KEY = "pk_test_51PwxqRHfUH12ERnhedrBaVA2p1e9N4gmUPuSNBS52WgH49Yb4Ee3wXZSLRGRVUmqajVKlGcM8E3xJ17GiYLK5brb00PhoMlmQq";
export const TEST_MODE_TRIPE_SECRET_KEY = "sk_test_51PwxqRHfUH12ERnhtPRmfi9kX9pCs9JtBD2Q7DqpAgaAi7wS3ZdgeY92A3HoYPWuW6psAJgNevqrC4NbPjdt2B9c00i7Z20iRt";