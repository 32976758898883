import { useState, createContext } from "react";

// Create the context
export const AppProvider = createContext();

// Create a provider component
const AppContextProvider = ({ children }) => {
    const [state, setState] = useState();
    const [prev, setPrev] = useState('');
    const [index, setIndex] = useState('');

    return (
        <AppProvider.Provider value={{ state, setState, prev,setPrev,index,setIndex}}>
            {children}
        </AppProvider.Provider>
    );
};

export default AppContextProvider; // Make sure to export as default
