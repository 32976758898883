import Footer from '../../../Shade/Footer/Footer';
import Headings from '../../../Shade/Headings/Headings';
import Line from '../../../Shade/Line/Line';
import './CourseOutline.css';

import corseright from "../../../Assets/corseright.svg";
import corseclock from "../../../Assets/corseclock.svg";
import corsedesktop from "../../../Assets/corsedesktop.svg";
import leftarrow from "../../../Assets/left_arrow.svg";
import rightarrow from "../../../Assets/rightarrow.svg";
import corsevideo from "../../../Assets/corsevideo.svg";
import corselock from "../../../Assets/corselock.svg";
import cartadded from "../../../Assets/cartadded.svg";
import cartempty from "../../../Assets/cartempty.svg";
import tickmark from "../../../Assets/carttick.svg";
import refresh from "../../../Assets/refresh.svg";
import rong from "../../../Assets/rong.svg";
import calander from "../../../Assets/calendar.svg";
import viewnow from "../../../Assets/viewnow.svg";
import watchnow from "../../../Assets/watchnow.svg";
import support from "../../../Assets/support.svg";
import kitimg from "../../../Assets/kitimg.svg";
import previmg from "../../../Assets/previmg.svg";
import award from '../../../Assets/award.svg';

import { Button, CardFooter, Modal, ModalBody, ModalHeader } from 'react-bootstrap';
import { useContext, useEffect, useState } from 'react';
import { API_URL, IMG_PATH } from '../../../server';
import { useLocation, useNavigate } from 'react-router-dom';

import ReactStars from "react-rating-stars-component";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import dayjs from 'dayjs';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useTheme } from '@emotion/react';
import { AppProvider } from '../../../ContextApi/AppContext';
import CourseOutlineModule from './CourseOutlineModule';
import { format } from 'date-fns';

const CourseOutline = () => {

    const { state, setState, prev, setPrev, index, setIndex } = useContext(AppProvider);
    const [modalname, setModalName] = useState('');
    const [chooseExpertSession, setChooseExpertSession] = useState(false);
    const [calanderModal, setCalanderModal] = useState(false);
    const [outlinedetails, setOutlineDetails] = useState([]);
    const [outlinedetailscompleted, setOutlineDetailsCompleted] = useState([]);
    const [courseHighlets, setCourseHighlets] = useState([]);
    const [courseSessions, setCourseSessions] = useState([]);
    const [date, setDate] = useState(new Date());
    const [cardDetails, setCardDetails] = useState([]);
    const [totalExpertData, setTotalExpertData] = useState([]);
    const [courseId, setCourseId] = useState('');
    const [ansId, setAnsId] = useState('');
    const [particularExpertName, setParticularExpertName] = useState('');

    const token = sessionStorage.getItem('token');
    const registerid = sessionStorage.getItem('registerid');
    const navigate = useNavigate();
    const loc = useLocation();
    const { subjectname, courseName, subid, coursename, accept_badge_url } = loc?.state;
    console.log('subjectname', subjectname, 'subid', subid, "courseName", courseName, 'accept_badge_url', accept_badge_url)

    const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const [disabledDays, setDisabledDays] = useState([]);
    const [selectedDate, setSelectedDate] = useState(date);
    const [expertId, setExpertId] = useState('');
    const parsedDate = new Date(selectedDate);
    const [daySlots, setDaySlots] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [selectedInputIndex, setSelectedInputIndex] = useState(null);
    const [subjectExpertId, setsubjectexpertid] = useState('');

    const [signout, setSignout] = useState(false);
    const [bookingIdToCancel, setBookingIdToCancel] = useState(null);
    const [hideSlots, setHideSlots] = useState([]);
    console.log(hideSlots, 'hideslots')

    const itemsPerPage = 8;
    const [storedValues, setStoredValues] = useState({});
    console.log(storedValues)
    const totalPages = Math.ceil(daySlots[0]?.slots.length / itemsPerPage);
    const currentSlots = daySlots[0]?.slots.slice(currentPage * itemsPerPage, (currentPage + 1) * itemsPerPage);
    const today = dayjs();
    const maxDate = today.add(1, 'month').endOf('month');

    useEffect(() => {
        getCardDetails();
        chooseExpert();
        const today = dayjs().format('YYYY-MM-DD');
        setSelectedDate(today);
    }, [])
    useEffect(() => {
        getExpertSettingById(subjectExpertId);
        setCurrentPage(0);
        setSelectedInputIndex(null);
    }, [selectedDate]);
    const openSignoutModal = (bookingid) => {
        setBookingIdToCancel(bookingid);
        setSignout(true);
    };
    const closeSignoutModal = () => {
        setSignout(false);
        setBookingIdToCancel(null);
    };
    const handleNext = () => {
        if (currentPage < totalPages - 1) {
            setCurrentPage(prev => prev + 1);
            setSelectedInputIndex(null);
        }
    };
    const handlePrev = () => {
        if (currentPage > 0) {
            setCurrentPage(prev => prev - 1);
            setSelectedInputIndex(null);
        }
    };
    const handleInputClick = (index, value) => {
        setSelectedInputIndex(index);
        setStoredValues(value);
    };
    // slots related functionality ended

    const getCardDetails = () => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", "Bearer " + token);

        const raw = JSON.stringify({
            "user_id": registerid
        });

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow"
        };

        fetch(API_URL + "getAllCourse", requestOptions)
            .then((response) => response.json())
            .then((result) => {
                if (result.Status == true) {
                    console.log("getAllCourse", result)
                    const filteredCourses = result?.data?.courses?.filter(course => course.courseName == courseName);
                    console.log('filteredCourses', filteredCourses)
                    setCourseId(filteredCourses[0]._id);
                    setCourseHighlets(filteredCourses[0].courseHighlights);
                    setCourseSessions(filteredCourses[0].course);

                    const odc = filteredCourses[0].course.filter(cour =>
                        cour.Answer[0]?.bookings[0]?.isCourseCompleted == 'true');
                    const od = filteredCourses[0].course.filter(cour =>
                        cour.Answer[0]?.bookings[0]?.isCourseCompleted == 'pending' ||
                        cour.Answer[0]?.bookings?.length === 0
                    );
                    console.log(odc, 'odc');
                    console.log(od, 'od');
                    setOutlineDetailsCompleted(odc);
                    setOutlineDetails(od);
                }
            })
            .catch((error) => console.error(error));
    }
    const cancelBooking = (bookingid) => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", "Bearer " + token);

        const raw = JSON.stringify({
            "user_id": registerid,
            "booking_id": bookingid
        });

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow"
        };

        fetch(API_URL + "cancelBooking", requestOptions)
            .then((response) => response.json())
            .then((result) => {
                console.log("cancelBooking", result);
                closeSignoutModal();
                getCardDetails()
            })
            .catch((error) => console.error(error));
    }

    const chooseExpert = () => {

        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", "Bearer " + token);

        const raw = JSON.stringify({
            "user_id": registerid,
            "mySubject": subjectname
        });

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow"
        };

        fetch(API_URL + "getMySubjectById", requestOptions)
            .then((response) => response.json())
            .then((result) => {
                if (result.Status === true) {
                    console.log("getMySubjectById", result);
                    setCardDetails(result.data);
                }
            })
            .catch((error) => console.error(error));
    }
    const bookmarkforuser = (expert_id) => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", "Bearer " + token);

        const raw = JSON.stringify({
            "user_id": registerid,
            "expert_id": expert_id
        });
        console.log(raw, 'raw')
        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow"
        };

        fetch(API_URL + "bookMarkForUser", requestOptions)
            .then((response) => response.json())
            .then((result) => {
                console.log("bookMarkForUser", result)
                chooseExpert()
            })
            .catch((error) => console.error(error));
    }

    const getExpertSettingById = (exid) => {
        setsubjectexpertid(exid);
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", "Bearer " + token);

        const raw = JSON.stringify({
            "expert_id": exid
        });
        console.log('exp', raw)
        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow"
        };

        fetch(API_URL + "getExpertSettingById", requestOptions)
            .then((response) => response.json())
            .then((result) => {
                if (result.Status === true) {
                    console.log('getExpertSettingById', result)
                    setTotalExpertData(result.data);
                    const disableddays = result.data.newTiming.filter(day =>
                        day.slots.some(slot => !slot.isBooked)).map(day => day.day);
                    setDisabledDays(disableddays)
                    const filterdayslots = result.data.newTiming.filter(sloat =>
                        sloat.day === days[parsedDate.getDay()] || sloat.day === days[parsedDate.getDay()].substring(0, 3)
                    );
                    const filterdates01 = result.data.courseBooking.filter(booking => booking.chooseDate.split('T')[0] === selectedDate);
                    const filterdates02 = result.data.bookings.filter(booking => booking.chooseDate.split('T')[0] === selectedDate);
                    console.log(filterdates01, "=======", filterdates02, "========", selectedDate)
                    setHideSlots([...filterdates01.map(items => items.chooseTime),
                    ...filterdates02.map(items => items.chooseTime)]
                    )
                    setDaySlots(filterdayslots);
                }
            })
            .catch((error) => console.error(error));
    }
    const bookexpert = () => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", "Bearer " + token);

        const raw = JSON.stringify({
            "user_id": registerid,
            "expert_id": expertId,
            "courseId": courseId,
            "answerId": ansId,
            "type": 'Video',
            "amount": totalExpertData?.amount,
            "chooseDate": selectedDate,
            "chooseTime": storedValues,
            "day": days[parsedDate.getDay()],
        });
        console.log(raw, 'raw')
        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow"
        };

        fetch(API_URL + "courseBookExpert", requestOptions)
            .then((response) => response.json())
            .then((result) => {
                if (result.Status === true) {
                    console.log("courseBookExpert", result)
                    toast?.success(result?.message);
                    setTimeout(() => {
                        navigate('/CardCourses');
                    }, 2000);
                }
                else {
                    toast.error(result?.message, {
                        autoClose: 2000,
                        onClose: () => {
                            if (result?.message == 'Insufficient session count in wallet. Please recharge your wallet.') {
                                navigate('/SettingsMainScreen/SettingWallet', {
                                    state: 6
                                })
                            }
                        }
                    });
                }
            })
            .catch((error) => console.error(error));
    }

    const handleAddToCalendar = (ups) => {
        console.log("calendar", ups);

        const date = new Date(ups.chooseDate);
        const formattedDate = date.toISOString().split('T')[0]; // Format as YYYY-MM-DD
        console.log("formattedDate", formattedDate);

        // Convert the formatted date to YYYYMMDD format for Google Calendar
        const startDate = formattedDate.replace(/-/g, ''); // Replace dashes to match Google Calendar format
        const endDate = startDate;
        // Extract the date and time from the API response
        const eventTitle = encodeURIComponent(`${ups?.name?.trim()} - ${ups?.preferredSessionType} Session`);
        const eventDetails = encodeURIComponent(`Join this session with ${ups?.name?.trim()}. Session type: ${ups?.preferredSessionType}. Amount: ${ups?.amount}.`);
        const location = encodeURIComponent('Online'); // Replace with actual location if available

        // Construct the Google Calendar URL
        const calendarUrl = `https://calendar.google.com/calendar/render?action=TEMPLATE&text=${eventTitle}&dates=${startDate}/${endDate}&details=${eventDetails}&location=${location}`;

        // Open the link in a new tab to allow the user to add the event
        window.open(calendarUrl, '_blank');
    };

    const ContactSupport = (num) => {
        navigate('/SettingsMainScreen/SettingContentSupport', {
            state: num
        })
    }
    const watchNow = (data) => {
        navigate("/RecordingScreen", {
            state: data
        });
    }

    const getNextAvailableSlot = (timings) => {
        if (!timings || timings.length === 0) return 'Not available';

        const nowUtc = new Date();
        const nowIst = new Date(nowUtc.getTime() + 5.5 * 60 * 60 * 1000);
        const currentDay = nowIst.toLocaleDateString('en-US', { weekday: 'long' }); // Get current day name

        let todaySlot = null;
        let tomorrowSlot = null;

        for (const timing of timings) {
            if (!timing || !timing.slots || !timing.day) continue;

            // Check for today's slots
            if (timing.day === currentDay) {
                for (const slot of timing.slots) {
                    if (slot && slot.isBooked === false && slot.timing) {
                        todaySlot = slot.timing; // Take the first available slot
                        break; // Stop checking once we find an available slot
                    }
                }
            }

            // Check for tomorrow's slots
            else if (timing.day === getNextDay(currentDay)) {
                for (const slot of timing.slots) {
                    if (slot && slot.isBooked === false && slot.timing) {
                        tomorrowSlot = slot.timing;
                        break; // Stop checking once we find an available slot
                    }
                }
            }

            // Stop early if slots for today and tomorrow are found
            if (todaySlot && tomorrowSlot) break;
        }

        // Return the next available slot
        if (todaySlot) {
            return (
                <>
                    <span className='ms-4'>Available</span><br />Today, {extractTime(todaySlot)}
                </>
            );
        }

        if (tomorrowSlot) {
            return (
                <div style={{ marginLeft: '-10px' }}>
                    Available<br />Tomorrow, {extractTime(tomorrowSlot)}
                </div>
            );
        }

        return 'Not available';
    };

    // Helper function to get the next day name
    const getNextDay = (currentDay) => {
        const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
        const currentIndex = daysOfWeek.indexOf(currentDay);
        return daysOfWeek[(currentIndex + 1) % 7];
    };

    // Helper function to extract time from slot text
    const extractTime = (slotText) => {
        const match = slotText.match(/\d{1,2}:\d{2} ?[APap][Mm]/);
        return match ? match[0] : '';
    };


    const parseTime = (time, now) => {
        try {
            const dateString = `${format(now, 'yyyy-MM-dd')} ${time}`;
            const parsedTime = new Date(Date.parse(dateString));
            return new Date(parsedTime.getTime() + 5.5 * 60 * 60 * 1000); // Convert to IST
        } catch (e) {
            return null;
        }
    };

    const formatTime = (time) => format(time, 'h:mm a');

    const viewCertificate = () =>{
        window.open('https://sandbox.credly.com/badges/65654f17-82f6-4f4a-bb27-2ff2259eb999/accept');
    }

    return (
        <div style={{ backgroundColor: '#F5F3EE' }} className="d-flex flex-column min-vh-100">
            <Headings screenHeadingName='courses.' />
            <p style={{ marginTop: '-20px' }}></p>
            <Line />
            <ToastContainer />
            {/* body started */}
            <div className='d-flex gap-5 mt-5' style={{ paddingLeft: '20px', paddingRight: '20px' }}>
                <div style={{ maxWidth: '450px' }}>
                    <dl>
                        <div>
                            <h1 className='cotitle1'>{coursename}</h1>
                        </div>
                        <div className='d-flex mt-3'>
                            <lable className='copara me-5 d-flex align-items-center'><img src={corseclock} className='me-1' />8-hour course</lable>
                            <label className='copara d-flex align-items-center'><img src={corsedesktop} className='me-1' />Desktop recommended </label>
                        </div>
                        <p className='copara mt-3'>Understand the basics of AI with key information about how it all works,
                            the important tools to leverage,and where the future of the industry is headed.</p>
                        <hr className='coline' />
                        <div className='d-flex' style={{ overflowX: 'scroll', maxWidth: '500px', scrollbarWidth: 'none' }}>
                            {
                                courseSessions?.map((cs, index) => (
                                    <div key={index}>
                                        <button className="cobtn1 copara me-5">{cs.Question}</button>
                                    </div>
                                ))
                            }
                        </div>
                        <dt className='copara mb-2 mt-4' style={{ fontFamily: 'AvenirNextLTPro-Bold', fontSize: '0.8rem' }}>Course Highlights</dt>
                        {
                            courseHighlets?.map((ch, index) =>
                                <div key={index}>
                                    <dd className='copara d-flex align-items-center'><img src={corseright} className='me-2' />{ch}</dd>
                                </div>
                            )
                        }
                        <hr className='coline' />
                        <div className='d-flex flex-column'>
                            <button className='cobtn2 d-flex ps-4 align-items-center'>Artificial Intelligence <img src={rightarrow} style={{ height: '0.9rem', width: '0.9rem' }} className='ms-auto me-3' /></button>
                            <button
                                onClick={() => navigate('/CardCourses')}
                                className='cobtn2 d-flex ps-4 align-items-center'><img src={leftarrow} style={{ height: '0.9rem', width: '0.9rem' }} className='me-3' />Courses</button>
                        </div>
                    </dl>
                </div>

                <div style={{ maxWidth: '500px' }}>
                    <div className='coursekit p-4 mb-4 mt-2'>
                        <div className='d-flex justify-content-between align-items-center'>
                            <span className='cotitle2'>Course Kit</span>
                            <span><img src={kitimg} style={{ width: '1.2rem', height: '1.2rem' }} /></span>
                        </div>
                        <hr className='coline mb-4' />
                        <p className='copara'>A collection of materials and resources you can access anytime to help you throughout your course.</p>
                        <hr className='coline' />
                        <span
                            onClick={() => {
                                setModalName('');
                                setTimeout(() => setModalName('coursekit'), 0);
                            }}
                            style={{
                                fontFamily: 'AvenirNextLTPro-Bold',
                                fontSize: '0.7rem',
                                cursor: 'pointer',
                            }}
                            className="copara ms-2 d-flex align-items-center"
                        >
                            Access now <img style={{ width: '0.6rem', height: '0.6rem' }} src={rightarrow} />
                        </span>
                    </div>
                    <hr className='coline' />
                    <h1 className='cotitle2'>Course Outline</h1>
                    <p className='copara mb-4 text-muted'>You must complete each session to move on to the next.
                        Feel free to book additional sessions with tutors for extra support anytime.</p>
                    <div>
                        {outlinedetailscompleted?.map((odc, index) => (
                            <div>
                                <div className='courseprev p-4 mb-3'>
                                    <div className='d-flex justify-content-between align-items-center'>
                                        <span className='cosubtitle'>Session {String(index + 1).padStart(2, '0')} Preview</span>
                                        <span><img style={{ width: '1.1rem', height: '1.1rem' }} src={previmg} /></span>
                                    </div>
                                    <hr className='coline' />
                                    <span
                                        onClick={() => {
                                            setIndex(String(index + 1).padStart(2, '0'))
                                            setPrev(odc?.preview);
                                            setModalName('');
                                            setTimeout(() => setModalName('preview'), 0);
                                        }}
                                        style={{ cursor: 'pointer', fontFamily: 'AvenirNextLTPro-Bold', fontSize: '0.7rem' }}
                                        className='copara ms-2 d-flex align-items-center'>View now <img style={{ width: '0.6rem', height: '0.6rem' }} src={rightarrow} />
                                    </span>
                                </div>
                                <div
                                    key={index}
                                    style={{ backgroundColor: '#E5ECE3', borderRadius: '8px' }}
                                    className='mb-3 p-4'
                                >
                                    <div className="d-flex justify-content-between">
                                        <div className="cosubtitle d-flex align-items-center">
                                            <span>{String(index + 1).padStart(2, '0')}</span>
                                            &nbsp;
                                            <span>{odc.Question}</span>
                                        </div>
                                        <div
                                            className="copara d-flex align-items-center"
                                            style={{ fontFamily: 'AvenirNextLTPro-Bold', fontSize: '0.7rem' }}
                                        >
                                            <img src={corsevideo} className="me-1" alt="video icon" />
                                            {odc.Answer?.[0]?.courseTime} min
                                        </div>
                                    </div>
                                    <hr className="coline" />

                                    {odc.Answer?.[0]?.answerText?.map((ans, idx) => (
                                        <span key={idx} className="copara d-flex align-items-center mb-2">
                                            <img src={corseright} className="me-2" alt="right icon" />
                                            {ans}
                                        </span>
                                    ))}
                                    <hr className="coline" />

                                    <div style={{ cursor: 'pointer' }}>
                                        <div className="d-flex justify-content-between">
                                            <div className="d-flex gap-2 align-items-center">
                                                <div>
                                                    <img
                                                        src={IMG_PATH + odc.Answer?.[0]?.bookings?.[0]?.expertDetails?.image}
                                                        style={{
                                                            maxWidth: '48px',
                                                            maxHeight: '48px',
                                                            borderRadius: '50%',
                                                            objectFit: 'cover',
                                                        }}
                                                        alt="Expert"
                                                    />
                                                </div>
                                                <div className="copara d-flex flex-column">
                                                    <span>{odc.Answer?.[0]?.bookings?.[0]?.expertDetails?.name}</span>
                                                    <span>{odc.Answer?.[0]?.bookings?.[0]?.chooseDate}</span>
                                                </div>
                                            </div>
                                            <div className="copara d-flex align-items-center">
                                                <img
                                                    src={viewnow}
                                                    style={{ width: '0.8rem', height: '0.8rem', marginRight: '0.2rem' }}
                                                    alt="View Feedback Icon"
                                                />
                                                <span>View Feedback</span>
                                            </div>
                                        </div>
                                        <hr className="coline" />
                                        <div className="d-flex justify-content-between">
                                            <div
                                                onClick={() => ContactSupport(9)}
                                                className="copara d-flex align-items-center">
                                                <img
                                                    src={support}
                                                    style={{ width: '1.2rem', height: '1.2rem', marginRight: '0.2rem' }}
                                                    alt="Support Icon"
                                                />
                                                <span style={{ color: '#7A0000' }}>Contact Support</span>
                                            </div>
                                            <div className="copara d-flex align-items-center">
                                                <img
                                                    src={watchnow}
                                                    style={{ width: '1rem', height: '1rem', marginRight: '0.2rem' }}
                                                    alt="Watch Now Icon"
                                                />
                                                <span onClick={() => watchNow(odc.Answer?.[0]?.bookings?.[0])}>Watch now</span>
                                                <img
                                                    src={rightarrow}
                                                    style={{ width: '0.8rem', marginRight: '0.2rem' }}
                                                    alt="Right Arrow Icon"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                        <hr className='coline mt-4 mb-4' />
                        {outlinedetails?.map((od, index) => (
                            <div>
                                <div className='courseprev p-4 mb-3'>
                                    <div className='d-flex justify-content-between align-items-center'>
                                        <span className='cosubtitle'>Session {String(index + outlinedetailscompleted.length + 1).padStart(2, '0')} Preview</span>
                                        <span><img style={{ width: '1.1rem', height: '1.1rem' }} src={previmg} /></span>
                                    </div>
                                    <hr className='coline' />
                                    <span
                                        onClick={() => {
                                            setIndex(String(index + outlinedetailscompleted.length + 1).padStart(2, '0'))
                                            setPrev(od?.preview);
                                            setModalName('');
                                            setTimeout(() => setModalName('preview'), 0);
                                        }}
                                        style={{ cursor: 'pointer', fontFamily: 'AvenirNextLTPro-Bold', fontSize: '0.7rem' }}
                                        className='copara ms-2 d-flex align-items-center'>View now <img style={{ width: '0.6rem', height: '0.6rem' }} src={rightarrow} />
                                    </span>
                                </div>
                                <div
                                    key={index}
                                    className={`bg-white coline p-4 mb-3`}
                                >
                                    <div className="d-flex justify-content-between">
                                        <div className="gap-1 d-flex align-items-center">
                                            <span className='cardindex'>{String(index + outlinedetailscompleted.length + 1).padStart(2, '0')}</span>
                                            &nbsp;
                                            <span className='cosubtitle'>{od.Question}</span>
                                        </div>
                                        <div
                                            className="copara text-nowrap d-flex align-items-center"
                                            style={{ fontFamily: 'AvenirNextLTPro-Bold', fontSize: '0.7rem' }}
                                        >
                                            <img src={corsevideo} className="me-1" />
                                            {od.Answer?.[0]?.courseTime || 'N/A'} min
                                        </div>
                                    </div>
                                    <hr className="coline" />

                                    {od.Answer?.[0]?.answerText?.map((ans, idx) => (
                                        <span key={idx} className="copara d-flex align-items-center mb-2">
                                            <img src={corseright} className="me-2" alt="right icon" />
                                            {ans}
                                        </span>
                                    ))}

                                    <hr className="coline" />
                                    {od.Answer?.[0]?.bookings?.length > 0 ? (
                                        <div style={{ cursor: 'pointer' }}>
                                            <div className="d-flex justify-content-between">
                                                <div className="d-flex gap-2 align-items-center">
                                                    <div>
                                                        <img
                                                            src={IMG_PATH + od.Answer?.[0]?.bookings?.[0]?.expertDetails?.image}
                                                            style={{
                                                                maxWidth: '48px',
                                                                maxHeight: '48px',
                                                                borderRadius: '50%',
                                                                objectFit: 'cover',
                                                            }}
                                                            alt="Expert"
                                                        />
                                                    </div>
                                                    <div className='copara'>
                                                        <span>{od.Answer?.[0]?.bookings?.[0]?.expertDetails?.name}</span>
                                                        <br />
                                                        <span>{od.Answer?.[0]?.bookings?.[0]?.chooseDate}</span>
                                                    </div>
                                                </div>
                                                <div
                                                    onClick={() => {
                                                        if (od.Answer?.[0]?._id) {
                                                            setAnsId(od.Answer[0]._id);
                                                            setChooseExpertSession(true);
                                                        }
                                                    }}
                                                    className="copara d-flex align-items-center"
                                                >
                                                    <img
                                                        src={refresh}
                                                        style={{ width: '0.8rem', height: '0.8rem', marginRight: '0.2rem' }}
                                                        alt="Refresh Icon"
                                                    />
                                                    <span>Change tutor</span>
                                                </div>
                                            </div>
                                            <hr className="coline" />
                                            <div className="d-flex justify-content-between">
                                                <div
                                                    onClick={() => openSignoutModal(od.Answer?.[0]?.bookings[0]?._id)}
                                                    className="copara d-flex align-items-center">
                                                    <img
                                                        src={rong}
                                                        style={{ width: '1rem', height: '1rem', marginRight: '0.2rem' }}
                                                        alt="Cancel Icon"
                                                    />
                                                    <span style={{ color: '#7A0000' }}>Cancel Booking</span>
                                                </div>
                                                <div
                                                    onClick={() => handleAddToCalendar(od.Answer?.[0]?.bookings[0])}
                                                    className="copara d-flex align-items-center"
                                                >
                                                    <img
                                                        src={calander}
                                                        style={{ width: '1rem', height: '1rem', marginRight: '0.2rem' }}
                                                        alt="Calendar Icon"
                                                    />
                                                    <span>Add to Calendar</span>
                                                </div>
                                            </div>
                                            <hr className='coline' />
                                        </div>
                                    ) : (
                                        <div
                                            className="d-flex justify-content-between align-items-center"
                                        >
                                            <span
                                                onClick={() => {
                                                    if (index < 1 && od.Answer?.[0]?._id) {
                                                        setAnsId(od.Answer[0]._id);
                                                        setChooseExpertSession(true);
                                                    }
                                                }}
                                                className="copara"
                                                style={{
                                                    fontFamily: 'AvenirNextLTPro-Bold',
                                                    fontSize: '0.7rem',
                                                    cursor: index >= 1 ? 'not-allowed' : 'pointer',
                                                    pointerEvents: index >= 1 ? 'none' : 'auto',
                                                    opacity: index >= 1 ? 0.6 : 1
                                                }}
                                            >
                                                Choose Session tutor
                                            </span>
                                            {index >= 1 && <img src={corselock} alt="lock icon" />}
                                        </div>
                                    )}
                                </div>
                                <hr className='coline mt-4 mb-4' />
                            </div>
                        ))}
                        {/* course completed sutificate started */}
                        {
                            accept_badge_url !== undefined ? (
                                <div className='mb-4'>
                                    <div className='coursecompleted p-4'>
                                        <div className='d-flex justify-content-between align-items-center'>
                                            <div style={{ fontSize: '1rem' }}>
                                                Course Completion
                                            </div>
                                            <div>
                                                <img src={award} />
                                            </div>
                                        </div>
                                        <hr className='coline' />
                                        <div className='d-flex align-items-center'>
                                            <span onClick={() => viewCertificate()}>View Certificate</span>
                                            <span><img src={rightarrow} /></span>
                                        </div>
                                    </div>
                                </div>
                            ) : (null)
                        }
                        {/* course completed sutificate ended */}
                    </div>
                </div>
            </div>
            {/* body ended */}
            <CardFooter className="mt-auto">
                <Line />
                <Footer />
            </CardFooter>

            <Modal centered show={chooseExpertSession}>
                <Modal.Body style={{ backgroundColor: '#F5F3EE', borderRadius: '8px', width: '500px' }}>
                    <Modal.Header className="btn btn-close ms-auto" onClick={() => setChooseExpertSession(false)} />
                    <div className='p-4'>
                        <p className='cotitle2'>Choose Session tutor</p>
                        <hr className='coline' />
                        <p className='copara'>If you'd like to learn more about an tutor before deciding,
                            we suggest checking out their profiles and booking an intro session
                            to see if they're the right fit.</p>
                        <hr className='coline' />
                        <div style={{ overflowY: 'scroll', height: '300px', scrollbarWidth: 'none' }}>
                            {
                                cardDetails?.map((carddetail, index) => (
                                    <div key={index}>
                                        <div className="card p-3 mb-3">
                                            <div>
                                                {/* First Row: Image, Name, and One-liner */}
                                                <div className="d-flex justify-content-between align-items-center mb-3">
                                                    <div className="d-flex align-items-center gap-3">
                                                        <img
                                                            src={IMG_PATH + carddetail.image}
                                                            alt="Card Avatar"
                                                            style={{ width: '80px', height: '80px', borderRadius: '50%' }}
                                                        />
                                                        <div>
                                                            <h6 className="cotitle2 mt-2" style={{ fontSize: '1rem' }}>
                                                                {carddetail.name}
                                                            </h6>
                                                            <p className="mb-0 copara">{carddetail.yourOneLiner}</p>
                                                            <span className="copara">Speaks: {carddetail.languages.join(', ')}</span>
                                                        </div>
                                                    </div>
                                                    <div className="d-flex flex-column align-items-end">
                                                        <div className="d-flex justify-content-end mb-3 gap-0">
                                                            {carddetail.isBookedMark ? (
                                                                <div onClick={() => bookmarkforuser(carddetail.user_id)} className="d-flex gap-0  align-items-center">
                                                                    <img src={cartadded} />
                                                                    <img src={tickmark} />
                                                                </div>
                                                            ) : (
                                                                <div onClick={() => bookmarkforuser(carddetail.user_id)} className="d-flex gap-0 align-items-center">
                                                                    <img src={cartempty} />
                                                                    <img src={tickmark} />
                                                                </div>
                                                            )}
                                                        </div>
                                                        <span className="cotitle2" style={{ fontSize: '0.9rem' }}>
                                                            {/* ${carddetail.pricePerMinute}/min */}
                                                            <span className="card-paragraph-favorite">{getNextAvailableSlot(carddetail?.newTiming)}</span>
                                                        </span>
                                                    </div>
                                                </div>

                                                {/* Second Row: Rating and Subject */}
                                                <div className="d-flex align-items-center gap-2" style={{ marginTop: '-10px' }}>
                                                    {/* Rating */}
                                                    <div className="me-2 d-flex flex-column">
                                                        <span>
                                                            <ReactStars
                                                                count={5}
                                                                value={Math.round(carddetail.rating)}
                                                                isHalf={false}
                                                                edit={false}
                                                                activeColor="#D9B65B"
                                                                size={19}
                                                            />
                                                        </span>
                                                        <span
                                                            className="copara ms-2"
                                                            style={{ fontFamily: 'AvenirNextLTPro-Bold', fontSize: '0.6rem' }}
                                                        >
                                                            {carddetail.setYourFeeForPreBookedSession} Sessions
                                                        </span>
                                                    </div>

                                                    {/* Subject Button */}
                                                    <div className="ms-1 d-flex flex-column">
                                                        <span
                                                            className="cocardbtn1 d-flex align-items-center mt-1"
                                                            style={{
                                                                width: 'auto',
                                                                padding: '5px 10px',
                                                                backgroundColor: '#f5f3ee',
                                                                borderRadius: '5px',
                                                            }}
                                                        >
                                                            {carddetail.mySubject[0]}
                                                            <img src={rightarrow} alt="Arrow" />
                                                        </span>
                                                    </div>
                                                    {/* Choose Button */}
                                                    <div className="ms-auto d-flex flex-column">
                                                        <span
                                                            className="cocardbtn2 mt-2"
                                                            onClick={() => {
                                                                setParticularExpertName(cardDetails[index].name)
                                                                getExpertSettingById(carddetail.user_id);
                                                                setExpertId(carddetail.user_id)
                                                                setChooseExpertSession(false);
                                                                setCalanderModal(true);
                                                            }}
                                                        >
                                                            Choose
                                                        </span>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

            {/* calander modal started */}
            <Modal centered show={calanderModal}>
                <ModalBody className='p-4'>
                    <div className='d-flex justify-content-between'>
                        <span
                            onClick={() => {
                                setCalanderModal(false);
                                setChooseExpertSession(true);
                            }}
                            style={{ fontFamily: 'AvenirNextLTPro-Bold', fontSize: '0.8rem', cursor: 'pointer' }}
                            className='d-flex align-items-center'>
                            <img src={leftarrow} style={{ width: '0.8rem' }} />
                            Back
                        </span>
                        <span className='btn btn-close ms-auto' onClick={() => setCalanderModal(false)}></span>
                    </div>
                    <div>
                        <dl>
                            <dd className='cotitle2 fs-5 mt-3'>Book a session</dd>
                            <dd className='copara'>With {particularExpertName}</dd>
                            {/*  */}
                            <div style={{ overflowY: 'scroll', height: '350px', scrollbarWidth: 'none' }}>
                                <dd style={{ marginTop: '20px', backgroundColor: '#F5F3EE', borderRadius: '15px', boxShadow: '0px 4px 4px 0px #00000040', border: '2px solid black' }}>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DateCalendar
                                            className="custom-calendar"
                                            value={selectedDate ? dayjs(selectedDate) : null}
                                            onChange={(newDate) => setSelectedDate(newDate?.format('YYYY-MM-DD'))}
                                            minDate={today}
                                            maxDate={maxDate}
                                            shouldDisableDate={(date) => {
                                                const dayOfWeek = dayjs(date).format('dddd');
                                                return !disabledDays.includes(dayOfWeek);
                                            }}
                                            dayOfWeekFormatter={(day) => {
                                                const dayIndex = day.day();
                                                const dayLabels = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'];
                                                return dayLabels[dayIndex];
                                            }}
                                        />
                                    </LocalizationProvider>
                                </dd>
                                {/*  */}
                                <div>
                                    <div className='d-flex justify-content-between align-items-center mt-3'>
                                        <div className='cotitle2'>Choose a time</div>
                                        <div className='copara d-flex' style={{ fontFamily: 'AvenirNextLTPro-Bold', fontSize: '0.8rem', cursor: 'pointer' }}>
                                            <span className='d-flex align-items-center me-4' onClick={handlePrev}><img src={leftarrow} alt="Previous" />Prev</span>
                                            <span className='d-flex align-items-center' onClick={handleNext}>Next<img src={rightarrow} alt="Next" /></span>
                                        </div>
                                    </div>

                                    <div className="d-flex flex-wrap mb-5">
                                        {currentSlots?.map((daySlot, index) => (
                                            !hideSlots.includes(daySlot.timing) && (
                                                <div key={index} className="col-3 ps-2 mb-2">
                                                    <input
                                                        readOnly
                                                        value={daySlot.timing.split(' - ')[0]}
                                                        type="text"
                                                        className="mt-3 coinput"
                                                        onClick={() => handleInputClick(index, daySlot.timing)}
                                                        style={{
                                                            backgroundColor: selectedInputIndex === index ? 'black' : '#F5F3EE',
                                                            color: selectedInputIndex === index ? 'white' : '#000000',
                                                        }}
                                                    />
                                                </div>
                                            )
                                        ))}
                                    </div>

                                </div>
                                {/*  */}
                                <button className='cobooknowbutton mb-3' onClick={() => bookexpert()}>Book now</button>

                            </div>
                        </dl>
                    </div>
                </ModalBody>
            </Modal>
            {/* related to past ended */}
            <Modal centered show={signout} className="modal">
                <Modal.Body className="modalbackgroundcolor modalhightandwidth" style={{ width: "512px", height: "227px" }}>
                    <p style={{ textAlign: "center" }} className="pass">Are you sure you want to Cancel Session?</p>
                    <div className="d-flex justify-content-center mb-5">
                        <Button style={{ fontFamily: "AvenirNextLTPro-Regular" }} variant="dark" onClick={closeSignoutModal} className="passbutton">
                            No
                        </Button>
                        <Button
                            style={{ fontFamily: "AvenirNextLTPro-Regular" }}
                            variant="dark"
                            onClick={() => {
                                cancelBooking(bookingIdToCancel);
                            }}
                            className="ms-3 passbutton"
                        >
                            Yes
                        </Button>
                    </div>
                </Modal.Body>
            </Modal>
            <CourseOutlineModule name={modalname} coursename={coursename} />
        </div>
    );
}
export default CourseOutline;