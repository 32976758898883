import React, { useState, useEffect } from 'react';
import { getFirebaseToken, onMessageListener } from '../../firebase';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "./Notification.css"
import { useNavigate } from 'react-router-dom';
import { API_URL, IMG_PATH } from '../../server';
import NotificationModal from '../NotificationModal/NotificationModal';

const App = () => {
  const [isTokenFound, setTokenFound] = useState(false);
  const [notification, setNotification] = useState(null);
  const [notificationdata, setnotificationdata] = useState(null);
  const [isVisible, setIsVisible] = useState(false);
  const [show,setShow]=useState(false);
  const navigate = useNavigate();
  // useEffect(() => {
  //   getFirebaseToken(setTokenFound);
  // }, []);

  onMessageListener()
    .then((payload) => {
      console.log("payload", payload);
      const { title } = payload?.notification?.title;
      setnotificationdata(payload?.data);
      showCustomNotification(title);
    })
    .catch((err) => console.log('Failed to receive message: ', err));


  const showCustomNotification = (title) => {
    setNotification({ title });
    setIsVisible(true);
    setTimeout(() => {
      setIsVisible(false);
    }, 10000);
  };

  const path = () => {

    if (notificationdata?.typeOfSession !== 'Chat') {
      const credentials = {
        data: {
          channelName: notificationdata?.channelName,
          token: notificationdata?.agoraToken
        }
      }

      const dataItems = {
        channel_response: credentials,
        booking_response: '',
        otherUserId: notificationdata?.user_id
      }
      if (notificationdata?.typeOfSession === 'Video') {
        navigate("/VideoScreen", {
          state: dataItems
        })
      } else {
        navigate("/AudioScreen", {
          state: dataItems
        })
      }
    } else {
      CreateChat_token();
    }

  }


  const CreateChat_token = async () => {
    try {
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      const raw = JSON.stringify({
        "channelName": notificationdata?.expert_id
      });

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow"
      };
      const response = await fetch(API_URL + 'generateRTMUserToken', requestOptions);
      const result = await response.json();
      console.log("result", result);
      if (!response.ok) {
        throw new Error("somenthing went wrong");
      }
      const userId = notificationdata?.expert_id;
      const otherId = notificationdata?.user_id;
      const userName = '';
      const userProfile = '';
      const bookedTime = '';
      const dataItems = {
        user_Id: userId,
        other_Id: otherId,
        chat_token: result?.data?.token,
        userName: userName,
        userprofile: userProfile,
        timechoosen: bookedTime
      }
      navigate("/ChatScreen", {
        state: dataItems
      });
    } catch (error) {
      console.log(error);
    }

  }


  const openModal =()=> {
    setShow(true);
  }

  const closeModal =()=> {
    setShow(false);
  }

  return (
    <>
      {isVisible && notification && (
        <div className="custom-notification">
          <div className="notification-header">
            <img src={ notificationdata?.image ? IMG_PATH + notificationdata?.image : require("../../Assets/notification.png")} 
              onError={(e) => e.target.src = require('../../Assets/notification.png')}
              className="notification-icon" />
          </div>
          <div className='notification-body-conatiner'>
          <span
            className="notificationbody-text"
            onClick={() =>
              notificationdata?.type !== "PreBookedSession" ? openModal() : null
            }
            style={{cursor:notificationdata?.type !== "PreBookedSession" ? 'pointer' : null }}  
          >
            {notificationdata?.body}
          </span>
              <>
                {notificationdata?.typeOfSession === 'Video' ? (
                  <span className="notification-title">{"Video Session"}</span>
                ) : notificationdata?.typeOfSession === 'Call' ? (
                  <span className="notification-title">{"Audio Session"}</span>
                ) : notificationdata?.typeOfSession === 'Chat' ? (
                  <span className="notification-title">{"Chat Session"}</span>
                ) : null}
                {notificationdata?.typeOfSession == 'Video' || notificationdata?.typeOfSession == 'Call' || notificationdata?.typeOfSession == 'Chat' ? (
                  <div className='video-auido-buttons'>
                    <button className='notification-video-audio' onClick={() => path()} >Accept</button>
                    <button className='notification-video-audio-cancel' onClick={() => setIsVisible(false)}>Reject</button>
                  </div>
                ) : null}
              </>
              <NotificationModal show={show} handleClose={closeModal}/>
          </div>
        </div>
      )}
    </>
  );
};

export default App;