import React, { useEffect, useState } from "react";
import './SettingSubscription.css';

//svg images started
import develop from "../../../Assets/develop.svg";
import enjoy from "../../../Assets/enjoy.svg";
import build from "../../../Assets/build.svg";
import contribute from "../../../Assets/contribute.svg";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { API_URL } from "../../../server";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
//svg images ended

const SettingSubscription = () => {

    const [subscriptionDetails, setSubscriptionDetails] = useState([]);
    const registerid = sessionStorage.getItem('registerid');
    const [errorModule, setErrorModule] = useState(false);
    const [loader, setLoader] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    const [userDetails, setUserDetails] = useState(null);
    const stripe = useStripe();
    const elements = useElements();
    const [error, setError] = useState(null);
    const [isProcessing, setIsProcessing] = useState(false);

    const token = sessionStorage.getItem('token');
    const navigate = useNavigate();

    useEffect(() => {
        getadminsubscriptionprice();
        mydetails();
    }, [])
    const paymentintent = () => {
        setLoader(true);
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        const raw = JSON.stringify({
            "amount": subscriptionDetails.subscriptionPrice * 100,
            "currency": subscriptionDetails.currency?.toLocaleUpperCase()
        });

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow"
        };
        fetch(API_URL + "createPaymentIntent", requestOptions)
            .then((response) => response.json())
            .then((result) => {
                console.log("createPaymentIntent", result);
                if (result.success == true) {
                    handleSubmit(result?.paymentIntent.client_secret)
                    mydetails();
                    setLoader(false);
                }
                else {
                    setErrorMsg(result.error);
                    setErrorModule(true);
                    setLoader(false);
                }
            })
            .catch((error) => console.error(error))
            .finally(() => {
                setLoader(false);
            });
    }
    const handleSubmit = async (client_secret) => {
        if (!stripe || !elements) {
            console.error("Stripe has not loaded yet.");
            return;
        }
        setIsProcessing(true);
        const cardElement = elements.getElement(CardElement);
        try {
            const { paymentIntent, error } = await stripe.confirmCardPayment(
                client_secret, // Replace with your actual client secret
                {
                    payment_method: {
                        card: cardElement,
                    },
                }
            );
            if (error) {
                setError(error.message);
                console.error("Payment failed:", error.message);
            } else if (paymentIntent && paymentIntent.status === "succeeded") {
                console.log("Payment succeeded:", paymentIntent);
                addfreesubscription(registerid)
            }
        } catch (err) {
            toast.success(err.message);
            console.error("Error during payment processing:", err.message);
        }
    };
    const getadminsubscriptionprice = () => {
        const myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + token);

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            redirect: "follow"
        };

        fetch(API_URL + "getAdminSubscriptionPrice", requestOptions)
            .then((response) => response.json())
            .then((result) => {
                console.log('getAdminSubscriptionPrice', result)
                setSubscriptionDetails(result.data[0]);
            })
            .catch((error) => console.error(error));
    }

    const mydetails = () => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", "Bearer " + token);

        const raw = JSON.stringify({
            "_id": registerid
        });

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow"
        };

        fetch(API_URL + "getUsers", requestOptions)
            .then((response) => response.json())
            .then((result) => {
                console.log("getUsers", result);
                setUserDetails(result.data);
            })
            .catch((error) => console.error(error));
    };

    const addfreesubscription = (regid) => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", "Bearer " + token);

        const raw = JSON.stringify({
            "user_id": regid
        });

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow"
        };

        fetch(API_URL + "addPremiumSubscription", requestOptions)
            .then((response) => response.json())
            .then((result) => {
                console.log('addPremiumSubscription', result);
                if (result.Status == true) {
                    toast.success(result.message, {
                        onClose: () => {
                            mydetails();
                            navigate('/CardCourses');
                        }
                    });
                }
                else {
                    toast.error(result.message);
                }
            })
            .catch((error) => console.error(error));
    }

    return (
        <div style={{ maxWidth: '531px' }}>
            <div>
                {loader && (
                    <div className="loader-backdrop">
                        <div className="loader"></div>
                    </div>
                )}
            </div>
            <div style={{ maxWidth: '531px' }}>
                <h5 className="heading">Recess Pathway</h5>
                <p className="para">Join Recess Pathway and jumpstart your career.</p>
                <div>
                    <div>
                        <div className="d-flex align-items-center gap-3 mt-4">
                            <span><img className="subscribeimg" src={develop} /></span>
                            <span className="copara">Develop in-demand skills through <strong>structured courses and certifications.</strong></span>
                        </div>
                        <div className="d-flex align-items-center gap-3 mt-2">
                            <span><img className="subscribeimg" src={build} /></span>
                            <span className="copara">Build a standout resume with our <strong>AI-powered resume builder</strong> <span className="text-muted">(on its way)</span></span>
                        </div>
                        <div className="d-flex align-items-center gap-3 mt-2">
                            <span><img className="subscribeimg" src={contribute} /></span>
                            <span className="copara">Contribute to real-world employer tasks through our industry portal <span className="text-muted">(on its way)</span></span>
                        </div>
                        <div className="d-flex align-items-center gap-3 mt-2">
                            <span><img className="subscribeimg" src={enjoy} /></span>
                            <span className="copara">Enjoy resource partner discounts on <strong>tools, supplies, and more.</strong></span>
                        </div>
                    </div>
                </div>
                <div className="mb-5">
                    {userDetails?.profile?.subscription == false ? (
                        <div>
                            <div className="mt-4">
                                <h6 className="text-muted mb-4">Enter your card details below</h6>
                                <CardElement className="card-element" />
                                <hr />
                                {/* <PaymentElement/> */}
                            </div>
                            <button onClick={paymentintent} className="subbutton subspan mt-3">
                                Join now for ${subscriptionDetails.subscriptionPrice}
                            </button>
                        </div>
                    ) : (
                        <hr style={{marginBottom:'-50px'}}/>
                    )}
                </div>
                <p className="subpara lh-base text-muted" style={{ marginTop: '20%' }}>You may see a holding charge as we pre-authorise your payment method. Don't worry, you won't actually be charged for this pre-authorisation.</p>
                <p className="subpara lh-base text-muted">Your free 60-day subscription begins today. After 60 days, you will automatically be charged $19 per month going forward. Cancel anytime.</p>
            </div>
        </div>
    );
}
export default SettingSubscription;