import { useEffect, useRef, useState } from 'react';
import "./SettingContentSupport.css";
import { API_URL } from '../../../server';

const SettingContentSupport = () => {
  const [peerMessage, setPeerMessage] = useState("");
  const [Messages, setMessages] = useState([]);
  const chatEndRef = useRef(null);

  const token = sessionStorage.getItem('token');
  const registerid = sessionStorage.getItem('registerid');

  const handleSendPeerMessage = () => {
    if (!peerMessage.trim()) return;

    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", "Bearer " + token);

    const raw = JSON.stringify({
      user_id: registerid,
      comment: peerMessage,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(API_URL + "addUserChat", requestOptions)
      .then((response) => response.json())
      .then(() => {
        setPeerMessage("");
        getChat();
      })
      .catch((error) => console.error(error));
  };

  useEffect(() => {
    getChat();
  }, []);

  useEffect(() => {
    scrollToBottom();
  }, [Messages]);

  const getChat = () => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", "Bearer " + token);

    const raw = JSON.stringify({
      user_id: registerid,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(API_URL + "getChatByUserId", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setMessages(result.data[0]?.conversation || []); // Ensure fallback to an empty array
      })
      .catch((error) => console.error(error));
  };

  const scrollToBottom = () => {
    chatEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const formatDate = (milliseconds) => {
    const date = new Date(milliseconds);
    const options = {
      day: "2-digit",
      month: "short",
      year: "2-digit",
      hour: "numeric",
      minute: "2-digit",
      hour12: true,
    };
    return date.toLocaleString("en-US", options);
  };

  return (
    <div className="chatscreen">
      <p className='chatHeading'>Contact support</p>
      <div className="chat-mainconatiner">
        <div className="chat-body-conatiner">
          {Messages?.map((message, index) => (
            <div
              key={index}
              className={`${message?.sender === "user" ? "chat-body-my-chat" : "chat-body-others-chat"}`}
            >
              <div className="message-text">{message?.comment}</div>
              <div className="message-time-text">{formatDate(message?.timestamp)}</div>
            </div>
          ))}
          <div ref={chatEndRef} />
        </div>
      </div>
      <div className="chat-footer">
        <div className="chat-inputbox-conatiner">
          <input
            placeholder="Type a message..."
            value={peerMessage}
            onChange={(e) => setPeerMessage(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                handleSendPeerMessage();
              }
            }}
          />
        </div>
        <div className="chat-sendicon">
          <img
            src={require("../../../Assets/chat-send.png")}
            className="sendIcon"
            onClick={() => handleSendPeerMessage()}
            alt="Send"
          />
        </div>
      </div>
    </div>
  );
};

export default SettingContentSupport;
